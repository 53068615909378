export default class Model {
    static routePrefix = null

    constructor(data) {
        this.data = data;
    }

    static async all() {
        let response = await axios.get(route(this.routePrefix + '.index'));

        return response.data.data.map(product => new this(product));
    }

    static async find(id) {
        let response = await axios.get(route(this.routePrefix + '.show', [id]));

        return new this(response.data.data);
    }

    get humanReadableTime() {
        return dayjs.duration(
            this.time.diff(dayjs())
        ).humanize(true);
    }

    get time() {
        return dayjs(this.data.created_at);
    }
}
