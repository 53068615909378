import Model from './Model';
import axios from 'axios';

export default class Product extends Model {
    static routePrefix = 'api.products';

    /**
     * Buy a new item of this list
     */
    buy() {
        if (! confirm('Er du sikker på at du vil kjøpe en ' + this.data.name  + ' for ' + this.data.price + 'kr?')) return;

        return axios.post(route('api.user.picks.store'), {
            code: this.data.ean,
        }).then(() => window.location.reload());
    }

    /**
     * Buy a new item of this list
     */
    async refillFridge( remaining_in_fridge = this.data.fridge_capacity) {
        if (! confirm('Er du sikker på at du vil flylle kjøleskapet med ' + remaining_in_fridge + ' ' + this.data.name + '?')) return;

        let response = await axios.put(route('api.products.update', this.data.id), {
            remaining_in_fridge
        });

        Object.assign(this.data, response.data.data);
    }
}
