<template>
    <ul role="list" class="divide-y divide-gray-200">
        <li v-for="user in users" class="py-4 px-6" @click.stop.prevent="selectedUser = user">
            <div class="flex space-x-3">
                <img class="h-6 w-6 rounded-full" src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80" alt="">
                <div class="flex-1 space-y-1">
                    <div class="flex items-center justify-between">
                        <h3 class="text-sm font-medium hover:text-blue-600 hover:underline cursor-pointer">{{ user.data.name }}</h3>
                    </div>
                    <user-summary :user="user"></user-summary>
                </div>
            </div>
        </li>

        <modal v-if="selectedUser" @close="selectedUser = null">
            <user-summary :user="selectedUser"></user-summary>

            <user-pick-timeline :user="selectedUser"></user-pick-timeline>
        </modal>
    </ul>
</template>

<script>
export default {
    data() {
        return {
            users: [],
            selectedUser: null,
        }
    },

    async mounted() {
        let picks = await Pick.all();

        this.users = _(picks).map(pick => pick.user).uniqBy(user => user.data.email).value();
    },
}
</script>
