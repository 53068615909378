import Model from './Model';
import User from './User';
import Product from './Product';

export default class Pick extends Model {
    static routePrefix = 'api.picks';

    constructor(data) {
        super(data);

        this.user = new User(data.user);
        this.product = new Product(data.product);
    }
}
