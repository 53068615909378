<template>
    <div class="">
        <div class="flex justify-between items-center">
            <div>
                <h1 class="text-lg inline">{{ value.data.name }}</h1>

                <span class="text-gray-400 text-sm">
                    (NOK {{ value.data.price }},-)
                </span>
            </div>

            <div v-if="refill" class="space-x-1">
                <x-button v-if="value.data.fridge_capacity != value.data.remaining_in_fridge" @click="value.refillFridge(value.data.remaining_in_fridge - 1)" class="h-9 bg-blue-300 hover:bg-blue-500">-</x-button>
                <x-button v-if="value.data.fridge_capacity != value.data.remaining_in_fridge" @click="value.refillFridge(value.data.remaining_in_fridge + 1)" class="h-9 bg-blue-300 hover:bg-blue-500">+</x-button>
                <x-button v-if="value.data.fridge_capacity != value.data.remaining_in_fridge" @click="value.refillFridge()" class="h-9">Fyll</x-button>
            </div>
            <x-button v-else-if="value.data.remaining_in_fridge > 0" @click="value.buy()" class="h-9">Kjøp</x-button>
        </div>

        <div class="mt-2 text-center" v-if="refill">{{ value.data.fridge_capacity - value.data.remaining_in_fridge }} mangler i kjøleskap</div>

        <div class="flex items-end mt-1 space-y-2 text-gray-400 text-sm space-x-2">
            <div class="flex-1 text-center">
                <strong>{{ value.data.remaining_in_fridge }}</strong> igjen i kjøleskap
                <progress-bar :value="value.data.remaining_in_fridge" :total="value.data.fridge_capacity"></progress-bar>
            </div>
            <div class="flex-1 text-center">
                <strong>{{ value.data.remaining_total }}</strong> igjen totalt
                <progress-bar :value="value.data.remaining_total" :total="value.data.total"></progress-bar>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Product,
            required: true,
        },

        refill: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
