require('./bootstrap');

import {createApp} from 'vue';
import Vue from 'vue';

import Product from './Classes/Models/Product';
import Pick from './Classes/Models/Pick';
import User from './Classes/Models/User';

window.Product = Product;
window.Pick = Pick;
window.User = User;

axios.get('/sanctum/csrf-cookie').then(response => {
    let app = createApp();

    // Register all the Vue components
    const files = require.context('./', true, /\.vue$/i)
    files.keys().map(key => app.component(key.split('/').pop().split('.')[0], files(key).default));

    app.mount('#app');
});
