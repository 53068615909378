<template>
            <div class="flex space-x-3">
                <img class="h-6 w-6 rounded-full" src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80" alt="">
                <div class="flex-1 space-y-1">
                    <div class="flex items-center justify-between">
                        <h3 class="text-sm font-medium hover:text-blue-600 hover:underline cursor-pointer" @click.stop.prevent="user = pick.user">{{ pick.user.data.name }}</h3>
                        <p class="ml-2 text-sm text-gray-500">{{ pick.humanReadableTime }}</p>
                    </div>
                    <p class="text-sm text-gray-500">Kjøpte en <strong>{{ pick.product.data.name }}</strong> til <strong>{{ pick.product.data.price }}</strong> kr.</p>
                </div>
            </div>
</template>

<script>
export default {
    props: {
        user: {
            required: true,
        },
    },
}
</script>
