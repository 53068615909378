<template>
    <pick-timeline v-if="user" :picks="user.picks"></pick-timeline>
</template>

<script>
export default {
    data() {
        return {
            user: null,
        }
    },


    async mounted() {
        this.user = await User.current();
    },
}
</script>
