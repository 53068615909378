<template>
    <pick-timeline v-if="user" :picks="user.picks"></pick-timeline>
</template>

<script>
export default {
    props: {
        user: {
            required: true,
        },
    },
}
</script>
