<template>
    <div class="w-full bg-gray-100 h-2 rounded-full">
        <div
            :style="'width: ' + percentage + '%'"
            class="h-full rounded-full bg-blue-400 ml-auto"
            :class="bgColor"
        ></div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            required: true,
        },
        total: {
            type: Number,
            required: true,
        },
    },

    computed: {
        percentage() {
            return this.value / this.total * 100;
        },

        bgColor() {
            if (this.percentage > 90) return 'bg-green-400';

            if (this.percentage < 35) return 'bg-red-300';
            if (this.percentage < 30) return 'bg-red-400';
            if (this.percentage < 20) return 'bg-red-500';
            if (this.percentage < 10) return 'bg-red-600';
            if (this.percentage < 5) return 'bg-red-800';

            return 'bg-blue-400';

        },
    },
}
</script>
