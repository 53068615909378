<template>
    <div v-for="product in products" class="px-6 py-4 bg-white border-b border-gray-200">
        <product-list-item :value="product" refill></product-list-item>
    </div>
</template>

<script>
export default {
    data() {
        return {
            products: [],
        };
    },

    async mounted() {
        this.products = await Product.all();
    }
}
</script>
