<template>
    <div v-if="user_" class="space-y-6 max-w-7xl mx-auto">
        <div class="bg-blue-800 overflow-hidden shadow-sm sm:rounded-lg flex divide-x divide-blue-700 whitespace-nowrap">
            <div class="flex-1 px-6 py-6">
                <div class="text-sm text-blue-200 uppercase">Skylder</div>
                <div class="text-white text-3xl">kr. {{ user_.ows() }},-</div>
            </div>
            <div class="flex-1 px-6 py-6">
                <div class="text-sm text-blue-200 uppercase">Antall</div>
                <div class="text-white text-3xl">{{ user_.picks.length }} stk.</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        user: {
            default: null,
        },
    },

    data() {
        return {
            user_: null,
        }
    },

    async mounted() {
        this.user_ = this.user ? this.user : await User.current();
    },
}
</script>
