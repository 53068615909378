<template>
    <pick-timeline :picks="picks"></pick-timeline>
</template>

<script>
export default {
    data() {
        return {
            picks: [],
        }
    },

    async mounted() {
        this.picks = await Pick.all();
    },
}
</script>
