const dateFormat = 'YYYY.MM.DD';

export default class PickStat {
    constructor(picks) {
        this.picks = picks.sort((a,b) => {
            return new Date(a.data.created_at) - new Date(b.data.created_at);
        }).map(pick => {
            return {
                pick,
                date: pick.time.format(dateFormat),
            }
        });

        let diff = picks[picks.length-1].time.diff(picks[0].time, 'days');
        this.labels = [0, ...Array(diff)].map((_, i) => {
            return picks[0].time.add(i, 'day').format(dateFormat);
        })

        this.groups = _.groupBy(this.picks, pick => pick.pick.product.data.name);
    }

    get datasets() {
        return _.map(this.groups, (picks, name) => {
            let groupedByDate = _.groupBy(picks, 'date');

            return {
                label: name,
                data: this.labels.map(date => (groupedByDate[date] || []).length),
            }
        });
    }
}
