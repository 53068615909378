<template>
    <div class="flex justify-end mr-4 sm:mr-0 " @click="open">
        <x-button>Scan</x-button>
    </div>

    <modal @close="close" v-if="isOpen" class="px-4 pt-5 pb-4">
        <product-list-item v-if="product" :value="product"></product-list-item>

        <barcode-scanner v-else @handle="onScan"></barcode-scanner>
    </modal>
</template>

<script>
export default {
    data() {
        return {
            isOpen: false,
            product: null,
        }
    },

    methods: {
        open() {
            this.product = null;
            this.isOpen = true;

            return true;
            window.setTimeout(() => {
                this.onScan(5000112637922);
            }, 1500);
        },
        close() {
            this.isOpen = false;
        },

        async onScan(value) {
            try {
                this.product = await Product.find(value);
            } catch (e) {
                alert('Produkt med id ' + value + ' ble ikke funnet');
                this.isOpen = false;
            }
        }
    }
}
</script>
