<template>
    <video  width="600" height="400" id="barcodeScanner"></video>

    <select v-if="videoInputDevices.length > 1" class="w-full rounded mt-3" v-model="selectedDeviceId">
        <option v-for="device in videoInputDevices" :value="device.deviceId">{{ device.label }}</option>
    </select>
</template>

<script>
import { BrowserBarcodeReader, BarcodeFormat } from '@zxing/library';

export default {
    data() {
        return {
            codeReader: null,
            selectedDeviceId: 0,
            videoInputDevices: [],
        }
    },

    watch: {
        selectedDeviceId(newValue, oldValue) {
            if (newValue == oldValue) return;

            this.listen();
        },
    },

    async mounted() {
        this.start()
    },

    unmounted() {
        if (!this.codeReader) return;

        this.codeReader.reset();
        this.codeReader = null;
    },

    methods: {
        async start() {
            this.codeReader = this.codeReader || new BrowserBarcodeReader()

            this.videoInputDevices = await this.codeReader.getVideoInputDevices();

            window.setTimeout(() => { // Just start fetching to correct
                this.codeReader.getVideoInputDevices().then(videoInputDevices => {
                    this.videoInputDevices = videoInputDevices;
                });
            }, 3000);

            this.selectedDeviceId = this.selectedDeviceId || _.last(this.videoInputDevices).deviceId;

            // this.listen(); // This is called in watch
        },

        async listen() {
            this.codeReader.reset();

            let result = await this.codeReader.decodeOnceFromVideoDevice(this.selectedDeviceId, 'barcodeScanner');

            this.$emit('handle', result.text);
        },
    }
}
</script>
