import Model from './Model';
import Product from './Product';

export default class User extends Model {
    static routePrefix = 'api.users';

    static _currentUser = null;

    _picks = null;

    static async current() {
        return this._currentUser
            ? Promise.resolve(this._currentUser)
            : axios.get(route('api.users.current'))
                .then(response => new this(response.data.data));
    }

    get picks() {
        if (this._picks) return this._picks;

        this._picks = [];

        axios.get(route('api.user.picks.index', [this.data.id])).then(response => {
            this._picks.push(...response.data.data.map(data => new Pick({
                user: this.data,
                product: data,
                created_at: data.pivot.created_at,
                updated_at: data.pivot.updated_at,
            })));
        });

        return this._picks;
    }

    ows() {
        return this.picks.reduce((partialSum, pick) => {
            return partialSum + pick.product.data.price
        }, 0);
    }
}
