<template>
    <canvas></canvas>
</template>

<script>
import PickStat from '../Classes/PickStat';
import { Chart, ArcElement, LineElement, BarElement, PointElement, BarController, BubbleController, DoughnutController, LineController, PieController, PolarAreaController, RadarController, ScatterController, CategoryScale, LinearScale, LogarithmicScale, RadialLinearScale, TimeScale, TimeSeriesScale, Decimation, Filler, Legend, Title, Tooltip, SubTitle } from 'chart.js';

Chart.register( ArcElement, LineElement, BarElement, PointElement, BarController, BubbleController, DoughnutController, LineController, PieController, PolarAreaController, RadarController, ScatterController, CategoryScale, LinearScale, LogarithmicScale, RadialLinearScale, TimeScale, TimeSeriesScale, Decimation, Filler, Legend, Title, Tooltip, SubTitle);

export default {
    props: {
        value: {
            required: true,
        },
    },

    data() {
        return {
            chart: null,
            availableColors: [
                '#003E6B',
                '#0F609B',
                '#2680C2',
                '#4098D7',
                '#62B0E8',
                '#84C5F4',
                '#B6E0FE',
                '#DCEEFB',
            ].map(color => {
                return {
                    backgroundColor: color,
                    borderColor: color,
                }
            }),
        };
    },

    mounted() {
        let test = new PickStat(this.value);

        this.chart = new Chart(this.$el, {
            type: 'bar',
            data: {
                labels: test.labels,
                datasets: test.datasets.map((dataset, index) => {
                    return Object.assign({}, dataset, this.availableColors[index]);
                }),
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true
                    }
                }
            }
        });
    }
}
</script>
